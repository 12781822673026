import { isDevEnv } from '../helpers/environment'

const sentryDsn =
  'https://1592b933fc6c44e5fbf5d77e77206a05@o4505917822205952.ingest.sentry.io/4505956977344512'
const bostadsratternaPartnerId = '5eb04b7921140a10832d61df'
const villaagarnaPartnerId = '62837c35b6fd4bd52ac1ace9'
const bostadsutvecklarePartnerId = '63510e1931fbb6ccce957230'
const telenorPartnerId = '62837c35b6fd4bd52ac1ace8'
const freeSamfPartnerId = '60f03773578b2c6f779b6ca4'

interface Config {
  BASE_URL: string
  ATLAS_BASE_URL: string
  SENTRY_ENVIRONMENT: string
  DEBUG: boolean
  SENTRY_DSN: string
  BOSTADSRATTERNA_PARTNER_ID: string
  VILLAAGARNA_PARTNER_ID: string
  BOSTADSUTVECKLARE_PARTNER_ID: string
  TELENOR_PARTNER_ID: string
  FREE_SAMF_PARTNER_ID: string
}

interface Environments {
  development: Config
  staging: Config
  production: Config
}

const Environments: Environments = {
  development: {
    BASE_URL: 'http://localhost:4040',
    ATLAS_BASE_URL: 'https://atlas.boappa.com',
    SENTRY_ENVIRONMENT: 'development',
    DEBUG: true,
    SENTRY_DSN: sentryDsn,
    BOSTADSRATTERNA_PARTNER_ID: bostadsratternaPartnerId,
    VILLAAGARNA_PARTNER_ID: villaagarnaPartnerId,
    BOSTADSUTVECKLARE_PARTNER_ID: bostadsutvecklarePartnerId,
    TELENOR_PARTNER_ID: telenorPartnerId,
    FREE_SAMF_PARTNER_ID: freeSamfPartnerId,
  },
  staging: {
    BASE_URL: 'https://stagingapi.boappa.com',
    ATLAS_BASE_URL: 'https://atlas.boappa.com',
    SENTRY_ENVIRONMENT: 'staging',
    DEBUG: true,
    SENTRY_DSN: sentryDsn,
    BOSTADSRATTERNA_PARTNER_ID: bostadsratternaPartnerId,
    VILLAAGARNA_PARTNER_ID: villaagarnaPartnerId,
    BOSTADSUTVECKLARE_PARTNER_ID: bostadsutvecklarePartnerId,
    TELENOR_PARTNER_ID: telenorPartnerId,
    FREE_SAMF_PARTNER_ID: freeSamfPartnerId,
  },
  production: {
    BASE_URL: 'https://wapi.boappa.se',
    ATLAS_BASE_URL: 'https://atlas.boappa.com',
    SENTRY_ENVIRONMENT: 'production',
    DEBUG: false,
    SENTRY_DSN: sentryDsn,
    BOSTADSRATTERNA_PARTNER_ID: bostadsratternaPartnerId,
    VILLAAGARNA_PARTNER_ID: villaagarnaPartnerId,
    BOSTADSUTVECKLARE_PARTNER_ID: bostadsutvecklarePartnerId,
    TELENOR_PARTNER_ID: telenorPartnerId,
    FREE_SAMF_PARTNER_ID: freeSamfPartnerId,
  },
}

const getConfig = (): Config => {
  if (isDevEnv()) {
    return Environments.development
  }
  return Environments.production
}

const config = getConfig()

export default config
